<template>
	<div>
		<div class="flex header">
			<h2>在场车辆信息</h2>
            <div class="flex">
                <el-button  @click="carStaticsImport=true">日车辆统计导入</el-button>
                <el-button  @click="$refs.importFile.importFile=true">在场车辆信息导入</el-button>
            </div>
		</div>
		<el-form ref="form" class="searchCriteria" label-suffix=":" :model="searchForm" label-width="90px">
			<div class="flex mb-1  ">
				<el-form-item label="停车场">
					<el-select v-model="searchForm.parkId" placeholder="请选择停车场" @change="handleSelectChange">
            <el-option label="全部" value=""></el-option>
						<el-option :label="item.parkingName" :key="ind" v-for="(item,ind) in parkingList"
											 :value="item.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="进场闸口">
					<el-select v-model="searchForm.chargeStation" placeholder="请选择进场闸口">
            <el-option label="全部" value=""></el-option>
						<el-option :label="item.name" :key="ind" v-for="(item,ind) in chargeStationSel"
											 :value="item.name"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="车辆类型">
					<el-select v-model="searchForm.carType" placeholder="请选择车辆类型">
            <el-option label="全部" value=""></el-option>
						<el-option :label="item.label" :key="ind" v-for="(item,ind) in carTypeSel" :value="item.val"></el-option>
					</el-select>
				</el-form-item>
			</div>
			<div class="flex mb-1  ">
				<el-form-item label="车主公司">
					<el-input v-model="searchForm.ownerCompany" placeholder="请填写车主公司"></el-input>
				</el-form-item>
				<el-form-item label="用户类型">
					<el-select v-model="searchForm.memberType" placeholder="请选择用户类型">
            <el-option label="全部" value=""></el-option>
						<el-option :label="item.name" :key="ind" v-for="(item,ind) in memberTypeList"
											 :value="item.name"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="车牌号码">
					<el-input v-model="searchForm.carNo" placeholder="请填写车牌号码"></el-input>
				</el-form-item>
			</div>
			<div class="flex ">

				<el-form-item label="进场时间">
					<el-date-picker
							v-model="searchForm.beginDate"
							type="datetime"
							placeholder="开始时间">
					</el-date-picker>
					--
					<el-date-picker
							v-model="searchForm.endDate"
							type="datetime"
							placeholder="结束时间">
					</el-date-picker>
				</el-form-item>
				<div class="averageWid btnGroup">
					<el-button @click="getData">查询</el-button>
					<el-button @click="reloadSearch">重置</el-button>
				</div>
			</div>
		</el-form>
		<div class="table">
			<el-table
					v-loading="loading"
					element-loading-text="拼命加载中"
					element-loading-spinner="el-icon-loading"
					element-loading-background="rgba(0, 0, 0, 0.8)"
					:data="tableData"
					:header-cell-style="{'background':'rgba(0, 103, 166, 0.3)','color': '#ffffff','fontWeight':'bold','textAlign':'center'}"
					height="calc(100vh - 430px)"
					style="width: 100%">
				<el-table-column
						prop="carNo1" align="center"
						label="车牌号码"
						width="100">
				</el-table-column>
			<!--	<el-table-column
						prop="carNo2" align="center"
						label="车牌号码2"
						width="100">
				</el-table-column>-->
				<el-table-column align="center"
												 label="进场时间"
												 width="140">
					<template slot-scope="scope">
						{{ scope.row.inTime|formatTimer }}
					</template>
				</el-table-column>
				<el-table-column
						prop="inCenty" align="center"
						label="进场闸口名称"
						width="120">
				</el-table-column>
				<el-table-column
						prop="carType" align="center"
						label="车辆类型"
						width="80">
				</el-table-column>
				<el-table-column
						prop="memberType" align="center"
						label="用户类型"
						width="80">
				</el-table-column>
				<!--<el-table-column align="center"
												 label="进场图片路径" show-overflow-tooltip min-width="120">
					<template slot-scope="scope">
						<span style="color: #567BFF">{{ scope.row.inImgPath }}</span>
					</template>
				</el-table-column>-->
				<el-table-column
						prop="memberName" align="center" show-overflow-tooltip
						label="车主姓名">
				</el-table-column>
				<el-table-column
						prop="memberCompany" align="center" show-overflow-tooltip
						label="车主公司">
				</el-table-column>
				<el-table-column
						prop="memberTel" align="center" show-overflow-tooltip
						label="联系电话">
				</el-table-column>
				<el-table-column
						prop="handler" align="center" show-overflow-tooltip
						label="办理人">
				</el-table-column>
			</el-table>
			<el-pagination
					class="pagination"
					@current-change="handleCurrentChange"
					:current-page="page.current"
					:page-size="page.size"
					layout="prev, pager, next, total"
					:total="page.total">
			</el-pagination>
		</div>
		<!--导入-->
		<importFile ref="importFile" :uploadUrl="uploadUrl" @reflush="reloadSearch"></importFile>


        <el-dialog
                top="0" center class="commonDia updateMem" :close-on-click-modal="false"
                :visible.sync="carStaticsImport" :show-close="false"
                width="600px" :close-on-press-escape="false">
            <template #title>
                <h1 class="tit">导入</h1>
            </template>
            <el-form label-position="top" ref="form" class="rowsInfo" label-suffix="：" label-width="80px" :model="formMem">
                <div class="flex" >
                    <el-form-item label="收费停车场">
                        <el-select v-model="formMem.parkId" placeholder="请选择收费停车场" >
                            <el-option :label="item.parkingName" :key="ind" v-for="(item,ind) in parkingList"
                                       :value="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="办理人">
                        <el-input v-model="formMem.handler" placeholder="请输入办理人" ></el-input>
                    </el-form-item>
                </div>
                <div class="flex">
                    <el-form-item label="导入文件">
                        <el-upload multiple
                                   ref="upload"
                                   class="upload-demo"
                                   action="string"
                                   name="uploadFile"
                                   :limit="1"
                                   :file-list="formMem.file"
                                   :before-upload="beforeAvatarUpload"
                                   :auto-upload="false"
                                   :on-success="successUpload"
                                   :http-request="handleChange"
                                   accept=".csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
                            >
                            <el-button class="uploadImg" type="primary"><i class="el-icon-plus avatar-uploader-icon"></i>文件上传
                            </el-button>
                        </el-upload>
                    </el-form-item>
                    <el-form-item label="统计时间">
                        <el-date-picker
                                v-model="formMem.handleDate"
                                type="date"
                                placeholder="统计时间">
                        </el-date-picker>
                    </el-form-item>
                </div>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="cancel" :disabled="disabled">取 消</el-button>
                <el-button type="primary" @click="importFileBtn" :disabled="disabled">确 定</el-button>
            </div>
        </el-dialog>
	</div>

</template>

<script>
import { getPresentCarInfo, getCarParkInfoList } from "@/api/api";
import moment from "moment";

moment.locale("zh-cn");
export default {
	name: "vehicleInfo",
	data() {
		return {
			uploadUrl: '/car/presentCarInfo/import',
            uploadUrlDaily:'/car/daily-car-detail/import',
			searchForm: {
				carNo:"",
				chargeParking: "",
				parkId: "",
				chargeStation: "",
				inCenty: "",
				ownerCompany: "",
				memberType: "",
				carType: "",
				beginDate: "",
				endDate: ""
			},
			chargeStationSel: [],
			carTypeSel: [{ val: "small", label: "small" }, { val: "large", label: "large" }],
			tableData: [],
			page: {
				current: 1,
				size: 10,
				total: 0
			},
			memberTypeList: [],
			parkingList: [],
			loading:false,
            carStaticsImport:false,
            formMem:{
                parkId:'',
                handler:'',
                handleDate:'',
                file:[]
            },
            disabled:false
		};
	},
	mounted() {
		this.getData();
		this.getMemberInfoTypeList();
		this.getParkingList();
	},
	methods: {
        beforeAvatarUpload(file){
            const isExcel = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || 'application/vnd.ms-excel'
            const isChargPark = this.formMem.parkId !== ''
            const isHandleDate = this.formMem.handleDate !== '' && this.formMem.handleDate !== null
            if (!isExcel){
                this.$message.error("上传文件只能是xlsx格式")
            }
            if (!isChargPark) {
                this.$message.error("请先选择停车场")
            }
            if (!isHandleDate){
                this.$message.error("请先选择统计时间")
            }
            return isExcel && isChargPark && isHandleDate
        },
        successUpload(response) {
            if (response.code == 200) {
                this.formMem = {
                    parkId:'',
                    handler:'',
                    handleDate:'',
                    file:[]
                }
                this.carStaticsImport = false
                this.$message.success("导入成功")
                this.disabled = false
            }
        },
        handleChange(param){
            const loading = this.$loading({
                lock: true,
                text: '上传中，请稍后.....',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            let formData = new FormData();
            formData.append('uploadFile',param.file)
            formData.append('parkId',this.formMem.parkId)
            formData.append('handler',this.formMem.handler)
            formData.append('handleDate',moment(this.formMem.handleDate).format("YYYY-MM-DD"))
            this.$postJson(this.uploadUrlDaily,formData).then(res=>{
                if (res.data.code == 200) {
                    this.formMem = {
                        parkId: '',
                        handler: '',
                        handleDate:'',
                        file: []
                    }
                    loading.close();
                    this.carStaticsImport = false
                    this.$message.success("文件正在上传中，请稍后查看")
                    this.disabled = false
                }else {
                    loading.close();
                    this.$message.error(res.data.msg)
                }
            })
        },
        importFileBtn(){
            if (this.formMem.parkId == '' || this.formMem.parkId == null){
                this.$message.error("请先选择停车场");
                this.disabled = false;
                return;
            }
            if (this.formMem.handleDate == '' || this.formMem.handleDate == null){
                this.$message.error("请先选择统计时间");
                this.disabled = false;
                return;
            }
            this.disabled = true
            this.$refs.upload.submit()
        },
        cancel(){
            this.carStaticsImport = false
            this.formMem = {
                parkId:'',
                handler: '',
                handleDate: '',
                file:[]
            }
        },
		handleSelectChange(val){
			this.searchForm.chargeStation = ""
			this.$get('/car/parking-gate/'+val).then(res=>{
				this.chargeStationSel = res.data.data
			})
		},
		getData() {
			this.loading = true
			this.searchForm.page = this.page.current;
			this.searchForm.size = this.page.size;
			if (this.searchForm.beginDate != "" && this.searchForm.endDate != "") {
				this.searchForm.beginDate = moment(this.searchForm.beginDate).format("YYYY-MM-DD HH:mm:ss");
				this.searchForm.endDate = moment(this.searchForm.endDate).format("YYYY-MM-DD HH:mm:ss");
			}

			getPresentCarInfo(JSON.stringify(this.searchForm)).then(res => {
				this.tableData = res.data.rows;
				this.page.total = res.data.total;
				setTimeout(()=>{
					this.loading = false
				},200)
			}).catch(err => {
				console.log("err: " + err);
			});
		},
		getParkingList() {
			let params = {
				page: this.page.current,
				size: 9999
			};
			getCarParkInfoList(JSON.stringify(params)).then(res => {
				this.parkingList = res.data.rows;
			});
		},
		getMemberInfoTypeList() {
			this.$get("/car/memberInfoType/list").then(res => {
				this.memberTypeList = res.data.data;
			});
		},
		handleCurrentChange(val) {
			this.page.current = val;
			this.getData();
		},
		reloadSearch() {
			this.searchForm = {
				chargeParking: "",
				parkId: "",
				chargeStation: "",
				inCenty: "",
				ownerCompany: "",
				memberType: "",
				carType: "",
				beginDate: "",
				endDate: ""
			};
			this.page.current=1
			this.getData();
		}
	},
	components: {
		"importFile": () => import("../member/importFile")
	}
};
</script>

<style lang="scss" scoped>
@import "../../assets/css/table";
.uploadImg {
    width: 138px;
    height: 42px;
    background: #F0F2F8;
    border: none;
    color: #999DAF;

    i {
        margin-right: 5px;
    }
}
</style>
